import {email, required, alpha_spaces, numeric, min, confirmed} from "vee-validate/dist/rules";
import {extend} from "vee-validate";
import {password} from "@core/utils/validations/validations";

extend("required", {
    ...required,
    message: "Это поле является обязательным"
});

extend("email", {
    ...email,
    message: "Поле email некорректно"
});

extend("alpha_spaces", {
    ...alpha_spaces,
    message: "Поле должно содержать только буквы"
});

extend("numeric", {
    ...numeric,
    message: "Поле должно содержать только цифры"
});

extend("min", {
    ...min,
    message: "Поле не может содержать менее 8 симоволов"
});

extend("password", {
    ...password,
    message: "Поле не может содержать менее 8 симоволов, должно содержать не менее одной малой буквы, одной " +
        "заглавной буквы и одного специального символа"
});

extend("confirmed", {
    ...confirmed,
    message: "Поля должны совпадать"
});
